import React from 'react'
import { useLocation } from '@reach/router'

import { useConfigFlag } from '../../hooks/useConfigFlag'
import AccesDeniedPage from '../../pages/accessDenied'
import { objInterface } from '../../types/General'

import { useSelector } from 'react-redux'
import { reducersTypes } from '../../redux/reducers'
import Loading from '../../components/Shared/Loading'
import { featureFlagsInit } from '../../redux/ducks/featureFlags/constant'
import {
  configurationRuleTypes,
  InitFeatureFlagsInterface
} from '../../redux/ducks/featureFlags/types'
import { isEmpty } from 'lodash'

interface props {
  children: React.ReactElement
}

const isPageDeniedFromConfiguration = (
  deny: objInterface,
  actualPathname: string
) => {
  const paths = actualPathname.split('/').slice(1)
  const pageDeny = paths.find((path: string) => deny[`/${path}`])

  if (pageDeny) {
    const rules = deny[`/${pageDeny}`]
    if (rules === '*') {
      return true
    }
  }
  return false
}

const getDenyPageConfig = (
  configuration?: configurationRuleTypes
): objInterface => {
  if (configuration && configuration.paths && configuration.paths.deny)
    return configuration.paths.deny
  return {}
}

const existsDenyPageConfigParam = (
  featureFlagInit: InitFeatureFlagsInterface
): boolean => {
  const { configuration } = featureFlagInit
  const denyRule = getDenyPageConfig(configuration)
  return !isEmpty(denyRule)
}

const WithPrivateRouteFeatureFlags: React.FC<props> = ({ children }) => {
  const { featureFlags } = useSelector((state: reducersTypes) => state)
  const { pathname: actualPathname } = useLocation()
  const pathsWithPermissions = featureFlagsInit.filter(
    existsDenyPageConfigParam
  )

  const { value: configuration } = useConfigFlag(
    'configuration'
  ) as objInterface

  const permissions = pathsWithPermissions.map((permission) => ({
    ...permission,
    ...useConfigFlag(permission.name)
  }))

  if (featureFlags.loading) {
    return <Loading />
  }

  if (!configuration) {
    return <AccesDeniedPage loading={featureFlags.loading} />
  }

  if (isPageDeniedFromConfiguration(configuration.paths.deny, actualPathname)) {
    return <AccesDeniedPage loading={featureFlags.loading} />
  }

  for (const permission of permissions) {
    const { configuration, value } = permission
    const denyRule = getDenyPageConfig(configuration)

    if (!value && isPageDeniedFromConfiguration(denyRule, actualPathname)) {
      return <AccesDeniedPage loading={featureFlags.loading} />
    }
  }

  return <>{children}</>
}

export default WithPrivateRouteFeatureFlags
