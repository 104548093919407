// Packages
import styled from 'styled-components'

export const StyledDetailOrderBeerUContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

export const StyledOrderNumberProduct = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #0b4793;
  &::after {
    content: '';
    width: 2px;
    height: 15px;
    transform: rotate(45deg);
    background-color: grey;
    position: relative;
    right: 49px;
    border-radius: 5px;
  }
  &::before {
    content: '';
    width: 2px;
    height: 15px;
    transform: rotate(-45deg);
    background-color: grey;
    position: relative;
    right: -28px;
    border-radius: 5px;
  }
`

export const StyledTitleDetailOrderBeerU = styled.p`
  width: 140px;
  font-size: 16px;
  font-weight: 600;
  margin: 0 27px 0 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const StyledDescriptionDetailOrderBeerU = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 400;
`

export const StyledPriceDetailOrderBeerU = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 100px;
  text-align: end;
`

export const StyledProductIconDetailOrderBeerU = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 30px;
`
