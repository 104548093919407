import React, { useEffect, useState } from 'react'
import { Result } from 'antd'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import { navigate } from 'gatsby'
import SearchEngineOptimization from '../../components/SEO'
import Loading from '../../components/Shared/Loading'

interface IAccesDeniedPage {
  loading: boolean
}

const AccesDeniedPage: React.FC<IAccesDeniedPage> = ({ loading }) => {
  const [title, setTitle] = useState('Cargando....')

  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        setTitle('Accesso Restringido')
      }
    }, 2000)

    return () => {
      isMounted = false
    }
  })

  return (
    <>
      {!loading && (
        <>
          <SearchEngineOptimization title={title} />
          <Result
            status="403"
            title="403"
            subTitle="No se puede acceder a esta página."
            extra={
              <StyledButtonPrimary
                data-testid="button-go-home"
                type="submit"
                onClick={() => navigate('/')}
              >
                Regresar al Inicio
              </StyledButtonPrimary>
            }
          />
        </>
      )}
      {loading && <Loading />}
    </>
  )
}

export default AccesDeniedPage
