// Packages
import React from 'react'

// Styled Components
import {
  StyledDetailOrderBeerUContainer,
  StyledOrderNumberProduct,
  StyledPriceDetailOrderBeerU,
  StyledTitleDetailOrderBeerU,
  StyledDescriptionDetailOrderBeerU,
  StyledProductIconDetailOrderBeerU
} from './styledComponents'

// Assets
import productIcon from '../../data/assets/icons/openBoxIcon.webp'

type props = {
  name: string
  quantity: number
  total: number
  packaging: string
  measurementMultiplier: string
  measurementUnit: string
  units: number
}

const DetailOrderBeerU: React.FC<props> = (props) => {
  const {
    name,
    quantity,
    total,
    packaging,
    measurementMultiplier,
    measurementUnit,
    units
  } = props
  return (
    <StyledDetailOrderBeerUContainer>
      <StyledOrderNumberProduct>
        {quantity} <StyledProductIconDetailOrderBeerU src={productIcon} />
      </StyledOrderNumberProduct>
      <StyledTitleDetailOrderBeerU>
        {`${name} ${packaging}`}
        <StyledDescriptionDetailOrderBeerU>
          {`${measurementMultiplier} ${measurementUnit} ${units}u`}
        </StyledDescriptionDetailOrderBeerU>
      </StyledTitleDetailOrderBeerU>
      <StyledPriceDetailOrderBeerU>${total}</StyledPriceDetailOrderBeerU>
    </StyledDetailOrderBeerUContainer>
  )
}

export default DetailOrderBeerU
