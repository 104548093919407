// Packages
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'

// Styled Components
import {
  StyledHeaderOrderBeerUContainer,
  StyledTitleOrderBeerUContainer,
  StyledDetailOrderBeerU,
  StyledOrderTypeOrderBeerU,
  StyledTypeIconOrderBeerU,
  StyledDescriptionOrderBeerU,
  StyledButtonDetailsOrderBeerU,
  StyleSeparatorOrderBeerU,
  StyledTotalDetailOrderBeerU,
  StyledTitleTotalOrderBeerU,
  StyledTotalProducstOrderBeerU,
  StyledTotalPriceOrderBeerU,
  StyledModalContainer,
  StyledSubTitleModal,
  StyledStatusModal,
  StyledClientName
} from './styledComponents'

// Components
import DetailOrderBeerU from '../../components/DetailOrderBeerU'
import Loading from '../../components/Shared/Loading'
import ModalResult from '../../components/Shared/ModalResult'
import ModalResponse from '../../components/Shared/ModalResponse'

// Utils
import { getParameter } from '../../utils/queryString'

// Assets
import deliveryIcon from '../../data/assets/icons/deliveryIcon.webp'
import pickupIcon from '../../data/assets/icons/pickupIcon.webp'

// QUeries
import { GET_REPORT_BEER_U } from '../../graphql/queries/Reports'
import { isEmpty } from 'lodash'
import { OrderBeerUTypes } from '../../components/ReportBeerU'

// Types
import { reducersTypes } from '../../redux/reducers'

const BeerUReportOrderContainer: React.FC = () => {
  let totalProducts = 0
  let totalPrice = 0
  const orderID = getParameter('orderId')
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
  const [orderData, setOrderData] = useState<OrderBeerUTypes>({
    orderID: '',
    orderType: '',
    status: '',
    colorStatus: '',
    deliveryDate: '',
    items: [],
    address: '',
    phone: ''
  })

  const { storeID, franchise, marketplace } = useSelector(
    (state: reducersTypes) => state.stores
  )

  const { data: dataReportBeerU, loading: loadingDataReportBeerU } = useQuery(
    GET_REPORT_BEER_U,
    {
      variables: {
        requestConfig: {
          marketplace,
          franchise
        },
        storeID,
        orderID
      },
      context: {
        clientName: 'api-instore'
      }
    }
  )

  const filterOrderData = () => {
    Object.values(
      dataReportBeerU.orderOmnichannelList.omnichannelOrders
    ).forEach((orderItem: any) => {
      if (orderItem.orderID === orderID) {
        const selectOrderData = {
          orderID: orderItem.orderID,
          orderType: orderItem.orderType,
          status:
            dataReportBeerU.orderOmnichannelList.statusList[
              orderItem.statusID - 1
            ].title,
          colorStatus:
            dataReportBeerU.orderOmnichannelList.statusList[
              orderItem.statusID - 1
            ].hexColor,
          deliveryDate: orderItem.delivery.deliveryDate,
          items: orderItem.items,
          address: orderItem.delivery.address,
          phone: orderItem.contactPhone,
          finishDate:
            orderItem.delivery.finishDate !== ''
              ? orderItem.delivery.finishDate
              : undefined,
          clientName: orderItem.clientName
        }
        setOrderData(selectOrderData)
      }
    })
  }

  useEffect(() => {
    if (
      !isEmpty(dataReportBeerU) &&
      !loadingDataReportBeerU &&
      orderID !== undefined
    ) {
      filterOrderData()
    }
  }, [dataReportBeerU])

  const headerOrderBeerU = (
    <StyledHeaderOrderBeerUContainer
      onClick={() => navigate('/reports/beeru?storeId=000000000')}
    >
      <LeftOutlined />
      <StyledTitleOrderBeerUContainer>
        Detalle de orden
      </StyledTitleOrderBeerUContainer>
    </StyledHeaderOrderBeerUContainer>
  )

  const detailOrderBeerU = (
    <StyledDetailOrderBeerU>
      <StyledOrderTypeOrderBeerU>
        {orderData?.orderType.toLowerCase()}
        <StyledTypeIconOrderBeerU
          src={orderData?.orderType === 'DELIVERY' ? deliveryIcon : pickupIcon}
        />
      </StyledOrderTypeOrderBeerU>
      <StyledDescriptionOrderBeerU>
        {`Orden ${orderData?.orderID}`}
      </StyledDescriptionOrderBeerU>
      <StyledDescriptionOrderBeerU>
        Estatus
        <StyledDescriptionOrderBeerU statusColor={orderData?.colorStatus}>
          {orderData?.status}
        </StyledDescriptionOrderBeerU>
      </StyledDescriptionOrderBeerU>
      <StyledDescriptionOrderBeerU>
        {`Completada el ${moment(orderData?.deliveryDate).format(
          'DD-MM-YYYY'
        )}`}
      </StyledDescriptionOrderBeerU>
      <StyledButtonDetailsOrderBeerU
        onClick={() => setIsVisibleModal(!isVisibleModal)}
      >
        Más detalles
      </StyledButtonDetailsOrderBeerU>
      <StyleSeparatorOrderBeerU />
    </StyledDetailOrderBeerU>
  )

  const detailContentOrderBeerU = orderData?.items.map((itemOrder) => {
    totalProducts += itemOrder.quantity
    totalPrice += itemOrder.total
    return (
      <DetailOrderBeerU
        key={itemOrder.sku}
        name={itemOrder.brandName}
        quantity={itemOrder.quantity}
        total={itemOrder.total}
        packaging={itemOrder.packaging}
        measurementMultiplier={itemOrder.measurementMultiplier}
        measurementUnit={itemOrder.measurementUnit}
        units={itemOrder.units}
      />
    )
  })

  const totalDetailOrderBeerU = (
    <StyledTotalDetailOrderBeerU>
      <StyledTotalProducstOrderBeerU>
        {totalProducts}
      </StyledTotalProducstOrderBeerU>
      <StyledDescriptionOrderBeerU>
        Artículos vendidos
      </StyledDescriptionOrderBeerU>
      <StyledTitleTotalOrderBeerU>Total:</StyledTitleTotalOrderBeerU>
      <StyledTotalPriceOrderBeerU>{`$${totalPrice.toFixed(
        2
      )}`}</StyledTotalPriceOrderBeerU>
    </StyledTotalDetailOrderBeerU>
  )

  const modalContent = (
    <ModalResult
      key="detail--order-modal"
      subTitle="Dirección de destino"
      extra={
        <StyledModalContainer key={'11111'}>
          <StyledSubTitleModal>
            Dirección: {orderData.address}
          </StyledSubTitleModal>
          <StyledSubTitleModal>Teléfono: {orderData.phone}</StyledSubTitleModal>
        </StyledModalContainer>
      }
    />
  )
  // clientName
  const modalContentForStatus8n10 = (
    <ModalResult
      key="detail--order-modal"
      // subTitle="Dirección de destino"
      extra={
        <StyledModalContainer key={'11111'}>
          <StyledSubTitleModal>
            <strong>ORDEN: #{orderData.orderID}</strong>
          </StyledSubTitleModal>
          <StyledSubTitleModal>
            Estatus:{' '}
            <StyledStatusModal statusColor={orderData.colorStatus}>
              {orderData.status}
            </StyledStatusModal>
          </StyledSubTitleModal>
          <StyledSubTitleModal>
            Fecha:{' '}
            {orderData.finishDate
              ? moment(orderData.finishDate).format('DD/MM/YYYY')
              : 'No disponible'}
          </StyledSubTitleModal>
          {orderData.clientName && (
            <StyledClientName>{orderData.clientName}</StyledClientName>
          )}
        </StyledModalContainer>
      }
    />
  )

  if (loadingDataReportBeerU) return <Loading />

  return (
    <>
      {headerOrderBeerU}
      {detailOrderBeerU}
      {detailContentOrderBeerU}
      {totalDetailOrderBeerU}
      <ModalResponse
        isModalVisible={isVisibleModal}
        content={
          orderData.status !== 'Completada' && orderData.status !== 'Cancelada'
            ? modalContent
            : modalContentForStatus8n10
        }
        onCancel={() => {
          setIsVisibleModal(false)
        }}
      />
    </>
  )
}

export default BeerUReportOrderContainer
