import React from 'react'
import { StyledModal } from './styledComponent'

interface Props {
  isModalVisible: any
  content: any
  onCancel: any
  onOk?: any
  closable?: boolean
  confirmLoading?: any
  width?: number
}

const ModalResponse: React.FC<Props> = ({
  isModalVisible,
  content,
  onCancel,
  onOk,
  closable,
  confirmLoading,
  width
}) => {
  return (
    <>
      <StyledModal
        visible={isModalVisible}
        onOk={onOk}
        closable={closable}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        footer={null}
        maskStyle={{ background: 'rgba(16, 53, 110, 0.89)' }}
        width={width}
      >
        {content}
      </StyledModal>
    </>
  )
}

export default ModalResponse
