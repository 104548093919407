import React from 'react'
import { StyledIconContainer } from './styledComponent'

type props = {
  icon: any
  height: number
  width: number
  action?: any
  disabled?: boolean
  cursor?: string
  opened?: boolean
}

const IconComponent: React.FC<props> = ({
  icon,
  height,
  width,
  action,
  disabled = false,
  cursor = 'auto',
  opened = false
}) => {
  return (
    <StyledIconContainer
      alt="iconContainer"
      src={icon}
      height={height}
      width={width}
      onClick={action}
      role="icon"
      disabled={disabled}
      cursor={cursor}
      opened={opened}
    />
  )
}

export default IconComponent
