import React from 'react'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import InfoCircleFilled from '@ant-design/icons/lib/icons/InfoCircleFilled'
import IconComponent from '../../Icon'
import WarningIcon from '../../../data/assets/icons/warning-icon.svg'
import { StyledResult } from './styledComponent'

interface Props {
  status?: 'success' | 'error' | 'info' | 'warning' | 404 | 403 | 500
  title?: string | undefined
  subTitle?: any
  extra: any
  icon?: any
}

const ModalResult: React.FC<Props> = ({
  status,
  title,
  subTitle,
  extra,
  icon
}) => {
  const renderIcon = (param: string | number | undefined) => {
    switch (param) {
      case 'success':
        return (
          <CheckCircleFilled twoToneColor="#52c41a" data-testid="success" />
        )
      case 'error':
        return <CloseCircleFilled twoToneColor="#c41a1a" data-testid="error" />
      case 'info':
        return <InfoCircleFilled twoToneColor="#1a9ac4" data-testid="info" />
      case 'warning':
        return <IconComponent icon={WarningIcon} width={52} height={52} />
      default:
        return icon
    }
  }

  return (
    <StyledResult
      status={status}
      title={title}
      subTitle={subTitle}
      extra={[extra]}
      icon={renderIcon(status)}
    />
  )
}

export default ModalResult
