import { Result } from 'antd'
import styled from 'styled-components'

interface StyledResultProps {
  status: 'success' | 'error' | 'info' | 'warning' | 404 | 403 | 500 | undefined
}

export const StyledResult = styled(Result)<StyledResultProps>`
  .ant-result-icon {
    margin-bottom: ${({ status }) => status === 'warning' && 0};
    img {
      width: ${({ status }) => status === 'warning' && '80px'};
      height: ${({ status }) => status === 'warning' && '80px'};
      transform: ${({ status }) => status === 'warning' && 'translateY(-15%)'};
    }
  }
`
