// Packages
import { navigate } from 'gatsby'
import queryString from 'query-string'

export const getParameter: (key: string) => undefined | string = (key) => {
  const parameter =
    typeof window !== 'undefined' &&
    queryString.parse(window.location.search)[key]
  return typeof parameter === 'string' ? parameter : undefined
}

export const removeParameter: (key: string, state?: any) => void = (
  key,
  state
) => {
  const parsed = queryString.parse(window.location.search)
  delete parsed[key]
  Object.keys(parsed).length > 0
    ? navigate(`?${queryString.stringify(parsed)}`, { state })
    : navigate(window.location.pathname, { state })
}

export const setParameter: (key: string, value: string, state?: any) => void = (
  key,
  value,
  state
) => {
  const parsed = queryString.parse(window.location.search)
  parsed[key] = value
  navigate(`?${queryString.stringify(parsed)}`, { state })
}
