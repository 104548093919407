import styled from 'styled-components'

interface Sizes {
  height: number
  width: number
  disabled: boolean
  cursor: string
  opened: boolean
}

export const StyledIconContainer = styled.img<Sizes>`
  margin: 10px;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : props.cursor)};
  transform: translateY(-50%) rotate(${(props) => (props.opened ? 180 : 0)}deg);
`
