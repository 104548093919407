/* istanbul ignore file */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Packages
import React, { useEffect, useState } from 'react'

import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'

//Pages
import AccesDeniedPage from '../../pages/accessDenied'

// Assets

//Utils
import { isLoggedIn } from '../../utils/storage'
import { sendPageGA } from '../../utils/googleAnalitycs'

import WithPrivateRouteFeatureFlags from '../../higherOrderComponents/WithPrivateRouteFeatureFlags'

import { reducersTypes } from '../../redux/reducers'
import RoutesTypes from '../../redux/ducks/routes/types'
import Loading from '../../components/Shared/Loading'

ReactGA.initialize(process.env.GATSBY_GTAG_ENV || 'g-dev')

const pageViewsTraking = (pathname: string) => {
  let pageView
  if (pathname === '*') pageView = '/not-found'
  else pageView = pathname
  sendPageGA(pageView)
}

const deleteLastSlash = (str: string): string => {
  const lastChar = str[str.length - 1]
  return lastChar === '/' ? str.slice(0, -1) : str
}

const getMainRoutesAndSubRoutesAllowed = (
  actualMainPath: string,
  routesList: RoutesTypes.Menu[]
): (string | undefined)[] => {
  const actualDashboardRoutes = routesList.find(
    ({ path }) => path === actualMainPath
  )

  let routesAllowed = [actualDashboardRoutes?.path]

  actualDashboardRoutes?.subpath.forEach(({ path, subpaths }) => {
    routesAllowed = [...routesAllowed, path, ...subpaths]
  })

  return routesAllowed
}

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { pathname: rawPathName } = useLocation()
  const pathname = deleteLastSlash(rawPathName)

  const { routes } = useSelector((state: reducersTypes) => state)
  const [isPathDeny, setIsPathDeny] = useState<boolean | null>(null)

  const { routesList, loading } = routes

  useEffect(() => {
    if (routesList.length) {
      const actualMainPath = '/' + pathname.split('/')[1]
      const routesAllowed = getMainRoutesAndSubRoutesAllowed(
        actualMainPath,
        routesList
      )
      const isThisPathAllowed = routesAllowed.find((path) => path === pathname)

      setIsPathDeny(!isThisPathAllowed)
    }
  }, [routesList])

  if (loading) {
    return <Loading />
  }

  if (isPathDeny === true) {
    return <AccesDeniedPage loading={loading} />
  }

  if (!isLoggedIn() && pathname !== `/auth/login`) {
    navigate('/auth/login')
    return null
  }

  pageViewsTraking(pathname)

  return (
    <WithPrivateRouteFeatureFlags>
      <Component {...rest} />
    </WithPrivateRouteFeatureFlags>
  )
}
export default PrivateRoute
