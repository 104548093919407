// Packages
import styled from 'styled-components'

type StyledDescriptionOrderBeerUTypes = {
  statusColor?: string
}

export const StyledHeaderOrderBeerUContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  .anticon {
    position: relative;
    right: 15%;
    color: #0b4793;
    svg {
      width: 25px;
      height: 30px;
    }
  }
`

export const StyledTitleOrderBeerUContainer = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
`

export const StyledDetailOrderBeerU = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledOrderTypeOrderBeerU = styled.h1`
  color: #0b4793;
  font-size: 16px;
  font-weight: 600;
  margin: 18px 0 0;
  text-transform: capitalize;
`

export const StyledTypeIconOrderBeerU = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`

export const StyledDescriptionOrderBeerU = styled.span<StyledDescriptionOrderBeerUTypes>`
  font-size: 15px;
  display: flex;
  margin-left: ${({ statusColor }) => statusColor && '8px'};
  margin-bottom: 0;
  color: ${({ statusColor }) => (statusColor ? statusColor : '#000')};
`

export const StyledButtonDetailsOrderBeerU = styled.p`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0b4793;
  cursor: pointer;
  margin: 8px 0 0;
`

export const StyleSeparatorOrderBeerU = styled.div`
  width: 312px;
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 15px;
`

export const StyledTotalDetailOrderBeerU = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
`

export const StyledTotalProducstOrderBeerU = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #0b4793;
  margin: 0 8px 0 0;
`

export const StyledTitleTotalOrderBeerU = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-left: 38px;
`

export const StyledTotalPriceOrderBeerU = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #0b4793;
  margin: 0 0 0 40px;
`

export const StyledModalContainer = styled.div``

export const StyledSubTitleModal = styled.p``

type StyledStatusModalProps = {
  statusColor?: string
}

export const StyledStatusModal = styled.span<StyledStatusModalProps>`
  color: ${({ statusColor }) => (statusColor ? statusColor : '#000')};
`

export const StyledClientName = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
`
