// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'

// Container
import BeerUReportOrderContainer from '../../../../containers/BeerUReportOrderContainer'

// Layout
import LayoutMain from '../../../../layout'

const Sales: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Orden de venta Beer U" />
      <WithPrivateRoute component={BeerUReportOrderContainer} />
    </LayoutMain>
  )
}

export default Sales
